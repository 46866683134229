import http from './httpCore';

export const getAllDraftOrPublishedProductRecords = async (currentUserOnly:Boolean, rolesAndGroups: any): Promise<any> => {
    let url = `/product/search/draftOrPublishedRecords${currentUserOnly ? "" : "/agency"}`;
    const queryOptions = {
      "query":"",
      "fields": "*,fileContent:[value v=\"\"]",
      "sort": "created desc",
      "offset": 0,
      "limit": 5000
    }
    const rolesAndGroupsHeaders ={
      headers: {
        "rolesandgroups": JSON.stringify(rolesAndGroups)
      }
    }

     // if its agency record search, add rolesandgroups headers
    if(!currentUserOnly){
        return http.post(url, queryOptions, rolesAndGroupsHeaders);
    }
    else{
      return http.post(url, queryOptions);
    }
    
}

export const searchAllDraftOrPublishedProductRecords = async (currentUserOnly, search, rolesAndGroups: any): Promise<any> => {
    let url = `/product/search/draftOrPublishedRecords${currentUserOnly ? "" : "/agency"}`;
    const queryOptions = {
      "query":`"${search}"`,
      "fields": "*,fileContent:[value v=\"\"]",
      "sort": "score desc",
      "offset": 0,
      "limit": 5000
    }
    const rolesAndGroupsHeaders ={
      headers: {
        "rolesandgroups": JSON.stringify(rolesAndGroups)
      }
    }

    // if its agency record search, add rolesandgroups headers
    if(!currentUserOnly){
        return http.post(url, queryOptions, rolesAndGroupsHeaders);
    }
    else{
      return http.post(url, queryOptions);
    }
    
}

